import React from "react";
import './App.css';
import Header from './components/Header/Header';
import Navigation from "./components/Navigation/Navigation";

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import Attributes from "./components/Attributes/Attributes";
import Portfolio from "./components/Portfolio/Portfolio";
// import Management from "./components/Management/Management";
import Experiments from "./components/Experiments/Experiments";

import Secret from "./components/Secret/Secret";

const rootPage = (<div className="App">
    <Navigation />
    <div id="main-content">
        <Header />
        <Attributes />
        <Portfolio />
        {/*<Management />*/}
        <Experiments />
    </div>
</div>)

const secretPage = (<div className="App">
    <div id="main-content">
        <Header />
        <Secret />
    </div>
</div>)

const errorPage = (
    <div className="App">
        <div id="main-content">
            <Header />
            <h1  className={'errorPageHeader'}>The requested page wasn't found.<br/>Please check the URL.</h1>
        </div>
    </div>
)

const router = createBrowserRouter([
    { path: "/",  element: rootPage, errorElement: errorPage},
    { path: "/secret-1022",  element: secretPage, errorElement: errorPage },
]);


function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
