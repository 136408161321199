import React, { useState } from 'react';

const LightboxVideo = ({ src, alt, title, Wrapper = 'div', zIndex = 100 }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Wrapper onClick={toggleIsOpen}>
            <span style={{textDecoration:'underline', cursor:'pointer'}}>{title}</span>
            {isOpen ?
                <div onClick={toggleIsOpen} style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    cursor: 'pointer',
                    zIndex,
                    textAlign: 'center'
                }}>
                    <video controls autoPlay width={'80%'} style={{
                        marginTop: '5%'
                    }}>
                        <source src={src}/>
                        </video>
                    />
                </div>
                : null}
        </Wrapper>
    );
};

export default LightboxVideo;