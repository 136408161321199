import React, { useState } from 'react';

const LightboxImage = ({ src, alt, title, Wrapper = 'div', zIndex = 100 }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Wrapper onClick={toggleIsOpen}>
            <span style={{textDecoration:'underline', cursor:'pointer'}}>{title}</span>
            {isOpen ?
                <div onClick={toggleIsOpen} style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    width: '100vw',
                    paddong: '50px',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    cursor: 'pointer',
                    zIndex
                }}>
                    <div style={{
                        width: '80%',
                        height: '80%',
                        backgroundImage: "url(" + src + ")",
                        marginLeft:'10%',
                        marginTop:'5%',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }}>
                        Hello
                    </div>
                    {/*<img src={src}*/}
                    {/*     alt={alt}*/}
                    {/*     style={{*/}
                    {/*         height: 'auto',*/}
                    {/*         width: '80%',*/}
                    {/*         position: 'absolute',*/}
                    {/*         left: '10%',*/}
                    {/*         top: '5%'*/}
                    {/*     }}*/}
                    {/*/>*/}
                </div>
                : null}
        </Wrapper>
    );
};

export default LightboxImage;