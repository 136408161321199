import React from "react";
import "./Secret.css";
import LightboxImage from "../LightboxImage"
import LightboxVideo from "../LightboxVideo"

export default function Secret() {

    return (
        <div  className={"full secret"}>
            <h2>Glow</h2>
            <LightboxVideo src={'video/drawing-and-scan-denver.mp4'} alt={""} title={"Drawing and Scanning"} />
            <span>Highlights of a user test for Visual Arts and scanning functionality.</span>
            <p></p>
            <LightboxVideo src={'video/tangram.mp4'} alt={""} title={"Tangram Remote Representation"} />
            <span>Snippet from a user test for Tangram aimed to identify how the user sees physical tangram pieces moved by the remote person.</span>
            <p></p>
            <LightboxVideo src={'video/MemoryStones_demo1.mp4'} alt={""} title={"G3D Memory Stones Demo"} />
            <span>A 3D memory match game running on the Amazon Glow device and being fed by 3D head position for the user.</span>
            <p></p>
            <LightboxVideo src={'video/fishes.mov'} alt={""} title={"G3D Fishes"} />
            <span>A 3D flocking simulation running on the Amazon Glow device. This aimed to push the boundaries of the device by allowing addition of hundreds of animated fish.</span>
            <p></p>

            <h2>Go</h2>
            <LightboxVideo src={'video/go-market-key-android.mp4'} alt={""} title={"Market Key"} />
            <span>Prototype showing one of 16 variations our designers were considering for the entry key to the Go store.</span>
            <p></p>
            <LightboxVideo src={'video/go-oobe-timing.mp4'} alt={""} title={"OOBE Video Timing"} />
            <span>A test harness for finding the right look and feel for the first interaction a customer has with the Amazon Go store.</span>
            <p></p>
            <LightboxVideo src={'video/go-associate-tool-paths.mp4'} alt={""} title={"Associate Tools Path"} />
            <span>A data parsing tool that is used to find inefficiencies in the tool associates us one the floor and refine them for efficiency.</span>
            <p></p>
            <LightboxVideo src={'video/go-bin-management-ui.mp4'} alt={""} title={"Bin Management"} />
            <span>Intuitive UI for creating labels using a strict labelling convention.</span>
            <p></p>
            <LightboxVideo src={'video/go-gather-ingredients-step-1.mp4'} alt={""} title={"Gather Ingredients"} />
            <span>A Scanner integrated web phone app that ensures the correct ingredients and required ingredients are collected for each recipe.</span>
            <p></p>
            <LightboxImage src={'images/po-receive-hmr.png'} alt={""} title={"HMR PO Receive IA Ingredients"} />
            <span>Information architecture app flow for receiving product at the kitchen.</span>
            <p></p>
            <LightboxImage src={'images/go-store-early.jpeg'} alt={""} title={"Early Go Store"} />
            <span>Plywood and cardboard mock up of the store.</span>
            <p></p>
            <LightboxImage src={'images/go-entry-led-grid.JPG'} alt={""} title={"Go Gates Visual Testing"} />
            <span>LED grids used to test visuals and density of materials covering them.</span>
            <p></p>
        </div>
    )
}