import React from "react";
import "./Header.css";
import { animated, useSpring } from '@react-spring/web'

export default function Header() {
   const spring_name = useSpring({
        delay: 0,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    const spring_email = useSpring({
        delay: 250,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    return (
        <header>
            <animated.div  style={{
                opacity: 0, ...spring_name
            }} className="floatleft headername"><h1><a href={"/"}>Gordon Mueller</a></h1></animated.div>
            <animated.div style={{
                opacity: 0, ...spring_email
            }} className="floatleft headercontact"><span className="icons">A</span>206.370.1892&nbsp;&nbsp; <a
                href='&#109;&#97;i&#108;to&#58;&#103;&#111;r%6&#52;&#111;nmue&#108;%6Cer&#37;40&#103;&#109;&#97;i&#108;%2Ec&#111;m'><span
                className="icons">B</span>go&#114;donmueller&#64;&#103;&#109;&#97;&#105;l&#46;&#99;om</a></animated.div>
            {/*<div className="floatleft headercontact headerresume" onClick={()=>{"window.open('resume.docx','_blank');"}}*/}
            {/*     title="Download Resume"><span className="icons">F</span>Download&nbsp;Resume*/}
            {/*</div>*/}
        </header>
    );

}