import React from "react";
import "./Attributes.css";
import { animated, useSpring } from '@react-spring/web'

export default function Attributes() {
    const spring_image = useSpring({
        delay: 500,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    const spring_a1 = useSpring({
        delay: 600,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    const spring_a2 = useSpring({
        delay: 700,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    const spring_a3 = useSpring({
        delay: 800,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    return (

        <div>
            <animated.img  style={{
                opacity: 0, ...spring_image
            }}  id="careerchartImg" src="images/skills.png" alt="Work Experience Infographic"></animated.img>

            <div id="attributes">
                <animated.aside style={{
                    opacity: 0, ...spring_a1
                }} className={"first"}>
                    <h2>What I Am</h2>
                    <p>A creator, out of the box thinker, tinkerer, and data driven design specialist. I thrive in ambiguous environments and chip away at that ambiguity using research and multi-modal prototypes. As a Senior Design Technologist for the past 7.5 years, I've been involved in
                        bringing Amazon Go and Amazon Glow to the world.</p>
                    <p>I build strong relationships with design, product, and software partners. I create and promote best practices as an individual contributor and team leader.</p>
                </animated.aside>
                <animated.aside style={{
                    opacity: 0, ...spring_a2
                }} className={"second"}>
                    <h2>What I Bring</h2>
                    <p>A visually keen eye with end-to-end skills and deep knowledge about user interaction, technologies, and how to merge them into new-to-world products. When I don't know how to do something, I learn it. Fast.</p>
                    <p>I have a strong bias for action and dive deep to understand problem spaces to gain confidence in a feasible solution. I deliver understandable, documented code and examples to ensure user experiences are implemented efficiently and accurately.</p>
                </animated.aside>
                <animated.aside style={{
                    opacity: 0, ...spring_a3
                }} className={"third"}>
                    <h2>How I Bring It</h2>
                    <p>
                        React, Phaser, BabylonJS,
                        HTML5, CSS3, JavaScript, jQuery, AJAX, Web Sockets, Web RTC
                    </p>
                    <p>
                        Java, Kotlin, Android,
                        C#, Unity, Python, Processing,
                        Hardware, Arduino, Sensors, 3D Printing
                    </p>
                    <p>
                        D3, Quicksight,
                        AWS Services,
                        Distributed Architectures,
                        Networking,
                        Virtual Environments
                    </p>
                    <p>
                        SDLC, Agile,
                        IA/UX,
                        Project Management,
                        Team Leadership,
                        Collaboration,
                        Mentorship
                    </p>

                </animated.aside>
            </div>
            <div className={"clear"}></div>
        </div>
    )

}