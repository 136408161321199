import React from "react";
import "./Navigation.css";
import $ from 'jquery'

export default function Navigation() {

   function goto(id) {
        const elem = $(id);
        console.log(elem);
        if(elem) {
            var top = parseInt(elem.offset().top);
            $("html, body").animate({scrollTop: top});
        }
    }

    return (
        <nav>
            <div onClick={()=>{goto('.App')}} title="Home"><span>E</span>Home</div>
            <div onClick={()=>{goto('#portfolio')}} title="Management"><span>C</span>Portfolio</div>
            {/*<div onClick={()=>{goto('#management')}} title="Management"><span>G</span>Management</div>*/}
            <div onClick={()=>{goto('#experiments')}} title="Experiments"><span>D</span>Experiments</div>
            {/*<div onClick={()=>{window.open('resume.docx','_blank')}} title="Download Resume">*/}
            {/*    <span>F</span>Download&nbsp;Resume*/}
            {/*</div>*/}
            {/*<div onClick={()=>{window.open('portfolio.pdf','_blank')}} title="Download Portfolio">*/}
            {/*    <span>F</span>Download&nbsp;Portfolio*/}
            {/*</div>*/}
        </nav>
    )
}