import React from "react";
import "./Experiments.css";
import LightboxVideo from "../LightboxVideo";
import { animated, useSpring } from '@react-spring/web'

export default function Experiments() {
    const spring_experiments = useSpring({
        delay: 1000,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    return (
        <animated.div  style={{
            opacity: 0, ...spring_experiments
        }} id="experiments" className={"compact"}>
            <h2>Experiments</h2>

            <article>
                <h2>Physical Music Maker</h2>
                <div className={'image'} style={{backgroundImage:'url(images/glow-make-music.png'}} title="Make Music"></div>
                <div>
                    <p>A kids music sequencer using CV and physical objects to control pitch and sound of instruments.</p>
                    <LightboxVideo src={'video/cycle-1-music-001.mov'} alt={"Physical Music"} title={"See it in action"} />
                    <p></p>
                </div>
            </article>

            <article>
                <h2>Occulus Rift Viewer</h2>
                <div className={'image'} style={{backgroundImage:'url(images/riftwalkthrough.png'}} title="Occulus Rift Environment Viewer"></div>
                <div>
                    <p>3D model walk-through framework using the Oculus Rift via Unity. Provides a deeper sense of place when viewing potential environment builds.</p>
                </div>
            </article>

            <article>
                <h2>RFID Wine Selector</h2>
                <div className={'image'} style={{backgroundImage:'url(images/WFGT_captures_2.jpg)'}} title="RFID Wine Selector"></div>
                <div>
                    <p>RFID tags embedded into wood tiles and wine bottles allow the user to mix and match attributes such as "oak" and "cedar" to narrow down the perfect wine.</p>
                </div>
            </article>

            <article>
                <h2>Large Touch Wall</h2>
                <div className={'image'} style={{backgroundImage:'url(images/touchme.jpg'}} title="Large Scale Touch Wall"></div>
                <div>
                    <p>Economically feasible 108" touch wall built using LLP methodology and Community Core Vision to prove out multi-user experiences on a vertical multi-touch surface.</p>
                </div>
            </article>

            <article>
                <h2>Audio Visualizer</h2>
                <div className={'image'} style={{backgroundImage:'url(images/waveform_visualizer.jpg'}} title="Processing Audio Visualizer"></div>
                <div>
                    <p>Real-time experimental audio visualizer written in Processing. Contains two visual modes and sensitivity settings.</p>
                </div>
            </article>

            <article>
                <h2>Kinect Audio Tracker</h2>
                <div className={'image'} style={{backgroundImage:'url(images/kats.jpg'}} title="Kinect Audio Tracker"></div>
                <div>
                    <p>Turret based directional audio system designed to send targeted messages to people in an enclosed space.
                        Spatial position was calculated using Kinect depth coordinates and translated into turret angles through Arduino hardware.</p>
                </div>
            </article>

            <article>
                <h2>Twitter Visualization</h2>
                <div className={'image'} style={{backgroundImage:'url(images/twitterverse.jpg'}} title="Twitter Visualization"></div>
                <div>
                    <p>3D WPF application written for an AIGA event tying together four local design firms. Tweets about each design firm during the event were aggregated as "planets" along with statistics.</p>
                    <LightboxVideo src={'video/Twitterverse_x264.mp4'} alt={"Twitterverse"} title={"See it in action"} />
                    <p></p>
                </div>
            </article>

            <article>
                <h2>Leap Shader Control</h2>
                <div className={'image'} style={{backgroundImage:'url(images/leap_shader_control.PNG'}} title="Leap Shader Control"></div>
                <div>
                    <p>Investigation of ThreeJS web page using custom ray-marched shaders with parameters controlled by hand gestures captured by a Leap controller.</p>
                </div>
                </article>

            <div className={"clear"}></div>
        </animated.div>
    )
}