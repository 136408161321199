import React from "react";
import "./Portfolio.css";
import LightboxImage from "../LightboxImage"
import LightboxVideo from "../LightboxVideo"
import { animated, useSpring } from '@react-spring/web'

export default function Portfolio() {
    const spring_portfolio = useSpring({
        delay: 1000,
        from: { opacity: 0.0 },
        to: { opacity: 1.0 },
    })

    return (

        <animated.div  style={{
            opacity: 0, ...spring_portfolio
        }} id="portfolio" className={"full"}>
            <article>
                <h2>Amazon Glow</h2>
                <div className={"left"}><img src="images/amazon-glow.jpg" alt="Amazon Glow"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Amazon.com: Senior Design Technologist</p>
                    <p>Amazon Glow is a new-to-world device aimed to connect children with their loved ones remotely to foster play. Projection and camera technologies make it seem like they are in the same room together, instead of miles apart. Our goals were to facilitate immersion, connection, and presence to effect long term childhood outcomes.</p>
                    <p>When I joined the team this product was in the early concepting phases, with questions surrounding hardware, usability, form factor, and experiences... just about everything but a vision. We started using off-the-shelf hardware components to see what was possible, and continued prototyping hardware and software experiences all the way post launch.
                        Using data-driven design by testing with children and parents, I created over a hundred quick-turn prototypes to assess the usability and delight of connected experiences, landing on a handful of flagship experiences and interaction modalities.</p>
                    <p>I built Android native and OSX toolsets that allowed myself, the entire User Experience team, and Computer Vision teams,
                        to scale and move quickly. These enabled us to utilize features such as computer vision, FireOS second screen support, input modalities, and bluetooth stacks for accessories directly on the device and long before they were available to use in the product.
                       </p>
                    <p>As a member of the Innovation Team responsible for identifying and assessing new product features, I routinely presented new concepts to leadership and collaborated with team leads to set product direction. I was instrumental in prototyping physical-to-digital interactions not possible on any other hardware today, including shared GlowBit (physical game pieces) interactions, remote crafting experiences, and Bluetooth-enabled accessories.</p>
                    <LightboxVideo src={'video/amazon-glow.mp4'} alt={"Amazon Glow Video"} title={"Watch a Video about Amazon Glow"} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/glow-core.png" alt="Amazon Glow - Core"/>&nbsp;</div>
                    <div className={"right"}>
                       <p><strong>Core Browsing Experience</strong> - Finding books and activities is the main function of the core browsing experience. I prototyped concepts for this experience in a matter of days using a 2D game framework to add rich interaction and high fidelity. Prototypes were used to gain confidence in a direction and directly feed the product roadmap.</p>
                        <LightboxVideo src={'video/environments.mp4'} alt={"Environments"} title={"See it in action"} />
                        <p></p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/glow-physical2.png" alt="Amazon Glow - Physical"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Physical to Digital Interactions</strong> - Using computer vision and multi-stream camera inputs, I was able to prototype, gain user feedback, and remove ambiguity for how physical objects would be
                            incorporated into the Glow ecosystem months before any work was done to support this in the software stack. This opened the door for many differentiating activities and accessories using the same base technology.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/glow-together.png" alt="Amazon Glow - Together"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Playing Together</strong> - Prototypes focused on bringing people together, including this one where a shared deck of cards, computer vision, and clever use of Web RTC made users feel like they are in the same room playing together. Connecting and creating an "in the room" presence was a key tenet I was instrumental in identifying.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/glow-uxlab.png" alt="Amazon Glow - UX Lab"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Usability Lab</strong> - Designs aren't great until they can be validated through real data. Working with children poses it's own unique challenges based on both cognitive and physical development - assumptions always needed to be questioned. I spearheaded specification and creation of a multi-room UX lab catered towards researching connected experiences. Usability test were run with internal and external candidates, allowing us to gain answers and insights quickly and inexpensively.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/glow-3d1.png" alt="Amazon Glow - 3D"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Broad Range of Experiences</strong> - I prototyped highly interactive flagship experiences using both 2D and 3D frameworks (web and native), as well as experiences integrating Android native real-time computer vision for physical input such as skeletal tracking and fiducial markers. These ranged from connected drawing, to air hockey style action games, to music making, to full blown immersive 3D find-and-search and Tamagotchi style activities.</p>
                    </div>
                </div>
            </article>

            <div id="portfolio" className={"fulldivider"}></div>

            <article>
                <h2>Amazon Go</h2>
                <div className={"left"}><img src="images/amazon-go.webp" alt="Amazon Go"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Amazon.com: Senior Design Technologist</p>
                    <p>Yes, you really can Just Walk Out.</p>
                    <p>From cardboard and plywood to a real working store, I worked closely with our user experience, software development teams, and product managers to prototype the next-level experience of making "just walking out" a reality. This role ranged from prototyping cueing experiences, to self service kiosks, to physical gate hardware with visual and audio cues, to the web-based toolsets kitchen and store staff used in their day-to-day job functions. No stone was left unturned, even different labelling systems and patented product packages were prototyped for functionality and user experience. I covered both the UX Design and Design Technologist roles for several key parts of projects, owning the end-to-end outcome of toolsets used by store associates and staff store planners.</p>
                    <LightboxVideo src={'video/amazon-go.mp4'} alt={"Amazon Go Video"} title={"Watch a Video about Amazon Go"} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/go-entry.png" alt="Amazon Go - Entry"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Entry Gates</strong> - What's a store if you can't get in? I was instrumental in creating the entry and exit experiences, prototyping the physical gate hardware audio and visual cues, customer flow, and stand-alone store application. The most surprising thing? People didn't know how to exit the store. We solved that.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/go-animatic.png" alt="Amazon Go - OOBE"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Out of Box Experience</strong> - To make entry smooth, I worked with our UX team and learned After Effects to create production ready animations for the first time entry experience,. These explained how the store works and what to expect.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/go-packaging.png" alt="Amazon Go - Packaging"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Packaging</strong> - In the early days, the Amazon Go system had strict requirements on the types of products it could support. Creating distinct packaging not only solved these restrictions, but also provided a brand opportunity to showcase in-house product lines. Working with kitchen staff and technology partners, I created packing variations with unique mechanisms that were simple to use in the kitchens and streamlined the food production processes - and worked well with the store tech.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/go-service.png" alt="Amazon Go - Service"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Associate and Self Service apps</strong> - The store doesn't run without associates stocking, stowing, receiving, and pricing products on shelf. It also doesn't run if buyers and store planners don't have the right tools. I was in charge of the entirety of the tools used by associates as well as those used by the store planners, both as a UX designer and as a technologist prototyping and refining the tools through user research. I also designed and prototyped self-service applications for customers to use to weigh and tare loose product.</p>
                    </div>
                </div>

                <div className={"subtopic"}>
                    <div className={"left"}><img src="images/go-usage-funnel.png" alt="Amazon Go - Usage funnel"/>&nbsp;</div>
                    <div className={"right"}>
                        <p><strong>Usage Visualization Dashboard</strong> - We collected a huge amount of data on the usage of applications used by our in-house buyers and planners. At the time, Amazon didn't have tools to visualize this data in a way that worked for us to identify usage funnels and optimization points, so I jumped in and created them. Seeing these data points and flows visual took the guesswork out of refining the usability of these toolset.</p>
                    </div>
                </div>
            </article>


            <div id="portfolio" className={"fulldivider"}></div>


            <article>
                <h2>Obliteride Registration Web Site</h2>
                <div className={"left"}><img src="images/obliteride.PNG" alt="Obliteride Registration Web Site"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Development team coordinator, software developer, technical client relations, vendor liaison</p>
                    <p>Obliteride is an extension of Fred Hutchinson Cancer Research Center aimed at raising donations and awareness for cancer research.
                        The bicycle ride is now an annual event, with the inaugural ride raising nearly $2 million.</p>
                    <p>I worked closely with our client and our internal design team to gather requirements, understand content, and create documentation.</p>
                    <p>The system integrated with Blackbaud Teamraiser, a widely known fundraising platform. My development tasks revolved around UI module development
                        using the Kentico CMS platform, Blackbaud configuration, system database and service creation for API integration, as well as hosting provider set up and deployment.</p>
                </div>
            </article>

            <article>
                <h2>Starbucks Vending Interface</h2>
                <div className={"left"}><img src="images/starbucks_canvas.jpg" alt="Starbucks Vending Interface"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: System architect, primary software developer, technical client relations, vendor liaison</p>
                    <p>Starbucks has broken into the vending space in the UK to provide superior coffee to the on-the-go customer.
                        My task was to engage with our internal design team to formulate user flows then implement a
                        flexible system to integrate with two hardware vendors, one based in the US and one in Switzerland.</p>
                    <p>The interface system is written using WPF and includes an inheritance based architecture to allow for
                        simple integration with multiple hardware vendors. It currently supports integration with MSMQ and Windows Service systems.</p>
                    <p>Hundreds of machines have been installed and are operational in grocery stores and petrol stations, with over 1000 machines planned.</p>
                    <p>Notable accomplishments in this application include creation of custom animation libraries to support
                        PNG based sequences for transparent video, state machine creation for complex hardware integration and error messaging,
                        screen management for custom drink creation. The initial phase of this application was on a quick turn schedule
                        with a production beta version complete in six weeks from design to delivery.</p>
                    <LightboxVideo src={'video/CornerCafeProto_x264.mp4'} alt={"Starbucks Video"} title={"See it in action"} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </article>

            <article>
                <h2>HTC One Pop-Up Stores</h2>
                <div className={"left"}><img src="images/htc_popup.jpg" alt="HTC One Pop-Up Stores"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Development team coordinator, developer of complex experience, analytics engine creation,
                        hardware testing and software deployment</p>
                    <p>With the launch of the HTC One phone, we pushed HTC to go beyond their normal marketing efforts
                        and make a real splash. The result is a series of eleven pop-up stores set up in malls across the US and Canada.</p>
                    <p>Each 20'x20' pop-up contains five interactive experiences along with two video experiences. These integrate seamlessly with
                        analog light-box displays. Working with EWI, a high-end custom build-out vendor, we were able to complete software
                        and integrate it into a build prototype in less than three months. All interactive experiences utilize web
                        technologies.</p>
                    <p>While my primary role on this project was to ensure creation of each experience ran smoothly, I was also a developer
                        on one of the more complex experiences which pulled real-time articles from various web sources into an off-line database
                        so an interface simulating HTC's new Blink Feed paradigm could display it and remain relevant for the lifetime of the
                        pop-up.</p>
                </div>
            </article>

            <article>
                <h2>OpenCar HMI Prototype</h2>
                <div className={"left"}><img src="images/hmi.jpg" alt="OpenCar HMI Prototype<"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Development team coordinator, framework architecture, module development</p>
                    <p>OpenCar's goal was to sell an HTML5 auto interface to leading car manufacturers. This prototype utilized
                        ground-breaking technologies like OpenGL, HTML5 audio, and video to provide a feasible prototype
                        of what a user centric auto interface could be.</p>
                    <p>Modules prototyped include Pandora, real-time auto statistics, multi-user UI customization, mapping,
                        and a 3D navigation interface utilizing custom shaders.</p>
                    <LightboxVideo src={'video/OpenCar_HMI_x264.mp4'} alt={"OpenCar Video"} title={"See it in action"} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </article>

            <article>
                <h2>GE Innovation Center</h2>
                <div className={"left"}><img src="images/ge.jpg" alt="GE Innovation Center"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Development team coordinator, hardware specification, content management development, software architecture collaboration, testing, client relationship</p>
                    <p>The build out of GE's 80,000 sq. ft. Innovation Center in Calgary, Canada incorporated a wide array of digital
                        experiences blended with analog and collaborative functionality. The concept for the center was conceived to allow for
                        a part guided tour/part work session space. Flash based interactive experiences were created to tell the story of GE's innovations
                        and guide users to understand how GE influences the community and businesses in Canada. Hard working, visually branded,
                        tools were created for use when scoping oil and gas system upgrades and general system efficiencies.</p>
                    <p>No small feat it's own right, specification of digital whiteboards, large-scale video walls, and real-time communication
                        hardware had to integrate nicely with the digital experiences and analog brand.</p>
                </div>
            </article>

            <article>
                <h2>Microsoft Visitors Center</h2>
                <div className={"left"}><img src="images/msvc.jpg" alt="Microsoft Visitors Center"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Team leader, lead developer on the NOAA sphere</p>
                    <p>Microsoft's expansion west across the 520 allowed them to create a new visitor center and we were chosen
                        to design it. The visitor center showcases Microsoft's rich history, diverse workforce, and of course technology.</p>
                    <p>We created three interactive pieces to augment Microsoft created technologies and analog experiences in the space.</p>
                    <p>I worked primarily on custom visualizations presented on a NOAA sphere. These visualizations extracted real-time data
                        from internal Microsoft data sources and RSS news feeds to show the breadth of Microsoft's online properties and OS installation base.
                        Custom visualizations were rendered to video formats accepted by the sphere's presentation infrastructure and integrated for
                        walk-through presentations.</p>
                </div>
            </article>

            <article>
                <h2>Madison Square Garden Sales Center</h2>
                <div className={"left"}><img src="images/msg.jpg" alt="Madison Square Garden Sales Center"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Conceptual collaborator, hardware and software collaborator for suite views</p>
                    <p>Remodeling a cultural icon like Madison Square Garden presents a multitude of challenges. We were brought in to
                        pre-sell multi-million dollar box suites and advertising opportunities before ground was even broken.</p>
                    <p>The sales center was created in a free commercial space in the arcade adjoining the Garden. This space allowed us to
                        create a scripted walk-through including historical storytelling and full-scale mock-ups of what the suites
                        will looks like once completed.</p>
                    <p>The showcase piece of the experience was two full-sized suites each with an immersive large-scale 3D display showing any
                        view of the floor from any spot in the newly designed Garden. This display was driven using a Wii-mote and
                        allowed for administrators to alter branding and advertising opportunities customized to the users touring the sales center.
                        Suites were sold within months and the experience has since been reused to sell to season ticket holders.</p>
                </div>
            </article>

            <article>
                <h2>Sears Tower Queueing Experience</h2>
                <div className={"left"}><img src="images/sears_tower.jpg" alt="Sears Tower Queueing Experience"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Lead developer for administration system, wait queue wall experience, and welcome messaging</p>
                    <p>Waiting in line to get to a world-class observation deck can be a frustrating experience. With the right
                        interactive it can be a great experience.</p>
                    <p>We blended education, games, and fun experiences to take pass the time waiting to get to the top. Visitors
                        are ushered into a well thought out queue containing trivia kiosks, interactive displays, and a theater experience
                        to keep them engaged - all managed through a custom central administration system allowing configuration of each experience
                        and statistics gathering to facilitate intelligent updates in the future.</p>
                </div>
            </article>

            <article>
                <h2>B Line Pulse Community Game</h2>
                <div className={"left"}><img src="images/bline_pulse.jpg" alt="B Line Pulse Community Game"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: System architect, lead developer of the desktop interface and back end, collaborator on mobile interface and rendering engine, client relationship, conceptual collaborator</p>
                    <p>We were lucky enough to win a bid to create a community art project sponsored by King County Metro and 4Culture.
                        The bid was to create a mobile experience that brought awareness to the new Metro B Line being instituted in the
                        Bellevue/Redmond area. Our entry was an application that spanned mobile and desktop utilizing game dynamics to
                        bring the community together creating artwork symbolizing the diverse ridership.</p>
                    <p>Participants were urged to answer a simple question each day through a two-step mobile phone process. Data points from
                        each answer were collected to pass into a configurable rendering engine, producing a unique piece of art or each day
                        of the yearlong project.</p>
                    <p>Participants gained respect through the use of badges and were display on a community leaderboard.</p>
                </div>
            </article>

            <article>
                <h2>Citation Shares Flight Planning</h2>
                <div className={"left"}><img src="images/citationshares_zion.jpg" alt="Citation Shares Zion"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: System architect, lead developer, client relationship, technical liaison, collaborator on IA and UX</p>
                    <p>Fractional jet airline Citation Shares was doing business on pen and paper - gather preferences, scheduling flights,
                        and planning itineraries for their multi-millionaire clients. We proposed a better way.</p>
                    <p>Working with the client's developer staff, we implemented a rich WPF interface to handle personalized
                        service starting with PBX integration for caller ID to the final push into their flight tracking system. The interface handles scheduling flight legs,
                        personal preferences for luggage, transportation, and food, alerts and notifications, airport NOTEMS, more.</p>
                    <p>Our interface connected to back end storage and notification systems via a REST interface developed by Citation Shares.
                        Data fed into this application in real-time, effecting their flight tracking and personnel scheduling systems.</p>
                </div>
            </article>

            <article>
                <h2>Tommy Bahama Web Site</h2>
                <div className={"left"}><img src="images/tommy_bahama.jpg" alt="Tommy Bahama Web Site<"/>&nbsp;</div>
                <div className={"right"}>
                    <p className={"role"}>Hornall Anderson: Lead developer</p>
                    <p>Tommy Bahama's brand has a definite feel - it is rich, relaxed, and presents a distinctive lifestyle. Their first foray
                        into ecommerce needed to portray all these qualities.</p>
                    <p>Working with Blue Martini as the commerce platform vendor, we created templates for a layered web experience
                        from home page through the commerce experience including integration with product selectors, image zoom capabilities, and other
                        rich client side functionality.</p>
                </div>
            </article>

            <article>
                <h2>Ripple Effect Web Site</h2>
                <div className={"left"}><img src="images/ripple_effect.jpg" alt="Foundation Rwanda Web Site"/>&nbsp;</div>
                <div className={"right"}>
                    <p  className={"role"}>Hornall Anderson: Lead developer, liaison with commerce vendor</p>
                    <p>Ripple Effect is a project started by Washington Statue University to bring new hope to regions in Africa affected
                        by drought and famine. It was an honor to work with them to create a micro funding web site to make peoples'
                        lives better. Implemented along with a commerce partner, the site provides a means to fund partial purchase
                        of the items people in the regions need most - wells, livestock, grains.</p>
                </div>
            </article>


            <article>
                <h2>Resource Forecasting Tool</h2>
                <div className={"left"}><img src="images/ocho.png" alt="Ocho Forecasting Tool"/>&nbsp;</div>
                <div className={"right"}>
                    <p  className={"role"}>Hornall Anderson: Lead developer, software development team lead</p>
                    <p>We needed something better than Excel to plan resources for the company. This resource forecasting tool, used company wide at Hornall Anderson, ensured availability and utilization goals are met - the key factor in the success of the company.</p>
                    <p>It includes an interactive real-time interface which visualizes underages and overages for people resources, integrates with Microsoft Dynamics AX for project budget planning, and created reports for current and future utilization based on a variety of filters for leadership to make informed decisions.</p>
                </div>
            </article>


            <div id="portfolio" className={"fulldivider"}></div>


            <article>
                <h2>Premera Blue Cross Web Site</h2>
                <div className={"left"}><img src="images/premera.jpg" alt="Premera Blue Cross Web Site"/>&nbsp;</div>
                <div className={"right"}>
                    <p>I was initially brought into the team to extend and maintain the existing ASP web site. Through the course
                        of my employment I lead the effort to create a new ASP.NET based portal site, which grew to handle distinct
                        secure portals for Members, Providers, and Brokers for both the Premera and Lifewise brands.</p>
                    <p>I was instrumental in architecting and implementing a .NET remoting system to drive data integration between
                        the portals and an internal 3rd party insurance application comprised of hundreds of tables and
                        thousands of stored procedures.</p>
                    <p>As the system architect and team leader, I was constantly in collaboration with the IT and test teams devising
                        new deployment methodologies, applications, and working on building efficient environments for testing and production.</p>
                </div>
            </article>


            <div id="portfolio" className={"fulldivider"}></div>


            <article>
                <h2>CVS Web Site</h2>
                <div className={"left"}><img src="images/cvs.jpg" alt="CVS Web Site"/>&nbsp;</div>
                <div className={"right"}>
                    <p>Development of the initial CVS.com site for launch and ongoing extension from start-up to full-fledged consumer portal.
                        My primary role focused on the ecommerce purchase path along with membership and order history functions. I also created
                        various desktop and web based business tools to aid in the process of creating dynamic promotions and coupons for use in the commerce experience.</p>
                </div>
            </article>

        </animated.div>
    )
}